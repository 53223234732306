import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutesModule } from './app-routes.module'
import { CoreModule } from '@src/core/core.module'
import { AppComponent } from './app.component'
import { ToastModule } from 'primeng/toast'
import { MessageService } from 'primeng/api'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'

@NgModule({
  declarations: [AppComponent],
  imports: [AppRoutesModule, BrowserAnimationsModule, BrowserModule, CoreModule, ToastModule],
  bootstrap: [AppComponent],
  providers: [provideHttpClient(withInterceptorsFromDi()), MessageService],
})
export class AppModule {}
