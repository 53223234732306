import { Component, OnInit } from '@angular/core'
import { KeycloakProfile } from 'keycloak-js'
import { ActivatedRoute, Router, UrlSegment } from '@angular/router'
import { AuthService } from '@src/core/services/auth.service'
import { MenuItem } from 'primeng/api'

import { TitleService } from '@src/core/services/title.service'
import { Environment } from '@src/environments/environment'

@Component({
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthLayoutComponent implements OnInit {
  public sidebar: boolean = false

  public loggedIn: boolean = false
  public user: KeycloakProfile = {}

  public logoMenu: string | null = Environment.LOGO
  public titleMenu: string | null = Environment.TITLE
  public sidebarMenuItems: Array<MenuItem> = Environment.LAYOUT_MENU
  public sidebarFooterItems: Array<MenuItem> = Environment.LAYOUT_FOOTER_MENU

  public headerMenuItems: MenuItem[] = []

  public currentRoute: UrlSegment | null = null
  public pageTitle: string | null = null

  constructor(
    private readonly auth: AuthService,
    private titleService: TitleService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  async ngOnInit(): Promise<void> {
    this.route.children[this.route.children.length - 1].url.subscribe((data) => {
      this.currentRoute = data[data.length - 1]
    })

    this.titleService.setTitle.asObservable().subscribe((title) => {
      this.pageTitle = title
    })

    this.loggedIn = await this.auth.isLoggedIn()
    if (this.loggedIn) {
      this.user = await this.auth.loadUserProfile()
      this.headerMenuItems = [
        {
          label: `
            <div class="p-3">
              <p class="my-0 font-bold">${this.user.email}</p>
            </div>
          `,
          escape: false,
        },
        {
          label: `
              <div class="flex align-items-center gap-2 px-4">
                <i class="pi pi-sign-out"></i>
                <p class="my-0">Déconnexion</p>
              </div>
            `,
          escape: false,
          command: () => this.onLogout(),
        },
      ]
    }
  }

  public get currentPage(): string {
    const currentUrl = this.router.url
    const matchingItem =
      this.sidebarMenuItems
        .reduce((items: any, item: MenuItem) => {
          return item?.items ? [...items, ...item.items] : items
        }, [])
        .find((item: MenuItem) => currentUrl.includes(item.routerLink)) || null
    return matchingItem ? matchingItem.label : this.pageTitle
  }

  public onLogout(): void {
    this.auth.logout()
  }

  public onSidebar(): void {
    this.sidebar = !this.sidebar
  }
}
