export const Environment = {
  API_PATH: 'api/',
  TITLE: 'Fluidilogix',
  LOGO: '/assets/logo.png',
  LAYOUT_MENU: [
    {
      separator: true,
    },
    {
      label: 'DÉPÔTS',
      items: [
        { label: 'Dépôts', routerLink: 'depots' },
        { label: 'Transports', routerLink: 'depots/transports' },
        { label: 'Clients', routerLink: 'depots/clients' },
      ],
    },
    {
      label: 'OPÉRATIONS',
      items: [
        { label: 'Entrée des emballés', routerLink: 'operations/emballes' },
        { label: 'Inventaires', routerLink: 'operations/inventaires' },
        { label: 'Remplissage des fûts', routerLink: 'operations/remplissage' },
        { label: 'Opération de purge', routerLink: 'operations/purge' },
        { label: 'Opération de reclassement', routerLink: 'operations/reclassement' },
        { label: 'Opération de reformage', routerLink: 'operations/reformage' },
      ],
    },
  ],
  LAYOUT_FOOTER_MENU: [
    {
      label: 'Utilisateurs',
      routerLink: 'utilisateurs',
      icon: 'pi pi-users',
    },
    {
      label: 'Référentiels',
      routerLink: 'referentiels',
      icon: 'pi pi-sliders-h',
    },
    {
      label: 'Sauvegardes',
      icon: 'pi pi-save',
    },
  ],
}
