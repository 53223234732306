import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core'
import { KeycloakService, KeycloakAngularModule } from 'keycloak-angular'
import { CommonModule } from '@angular/common'
import { PrimeNGConfig } from 'primeng/api'

import { keycloakInitializer } from './keycloak-initializer'
import { AuthService } from './services/auth.service'
import { AuthGuard } from './guards/auth.guard'

import { registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/fr'

registerLocaleData(localeFr)

@NgModule({
  imports: [CommonModule, KeycloakAngularModule],
  providers: [
    {
      useFactory: keycloakInitializer,
      provide: APP_INITIALIZER,
      deps: [KeycloakService],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'fr' },
    AuthGuard,
    AuthService,
  ],
})
export class CoreModule {
  constructor(public primeNGConfig: PrimeNGConfig) {
    this.primeNGConfig.setTranslation({
      accept: 'Oui',
      reject: 'Non',
      apply: 'Appliquer',
      clear: 'Effacer',
      addRule: 'Ajouter un critère',
      matchAll: 'Remplit tous les critères',
      matchAny: 'Remplit au moins un critère',
      startsWith: 'Commence par',
      contains: 'Contient',
      notContains: 'Ne contient pas',
      endsWith: 'Finit par',
      equals: 'Egal à',
      notEquals: 'Différent de',
      dateIs: 'La date est le',
      dateIsNot: "La date n'est pas le",
      dateBefore: 'La date est avant le',
      dateAfter: 'La date est après le',
      dayNames: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
      dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
      dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
      monthNamesShort: ['Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jul', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'],
      monthNames: [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre',
      ],
      today: "Aujourd'hui",
      weekHeader: 'Semaine',
      dateFormat: 'dd/mm/yy',
      firstDayOfWeek: 1,
    })
  }
}
