import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthLayoutComponent } from '@src/layouts/desktop/auth/auth.component';
import { AuthGuard } from '@src/core/guards/auth.guard';
import { LayoutsModule } from '@src/layouts/desktop/layouts.module';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full',
  },
  {
    path: 'account',
    component: AuthLayoutComponent,
    data: { roles: ['admin', 'stock_manager'] },
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/account.module').then((m) => m.AccountModule),
  },
  { path: '**', redirectTo: 'account' },
];

@NgModule({
  imports: [LayoutsModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutesModule { }
